import { httpCilent } from "./httpClient";

import { IGetTrackingDetail } from "../features/trackings/forms/GetTrackingDetailForm";
import {
  IResponse,
  ITrackingDetail,
} from "../features/trackings/models/GetTrackingDetailModel";

export const getTrackingDetail = async (form: IGetTrackingDetail) => {
  const { data } = await httpCilent.post<Promise<IResponse<ITrackingDetail>>>(
    `/order-trackings/detail`,
    form
  );

  return data;
};
