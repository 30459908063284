import { getTrackingDetail } from "../../clients/tracking";

import { IGetTrackingDetail } from "./forms/GetTrackingDetailForm";
import { IResponse, ITrackingDetail } from "./models/GetTrackingDetailModel";

export async function getTracking(
  form: IGetTrackingDetail
): Promise<IResponse<ITrackingDetail>> {
  const response = await getTrackingDetail(form);
  return response;
}
