var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"status mt-5"},[_c('div',{staticClass:"status-item-box"},[_c('div',{staticClass:"status-item",class:{ active: _vm.isWaitingForProcess, current: _vm.isCurrentStatus(1, 5) }},[_c('v-icon',{attrs:{"dark":"","size":"4vw"}},[_vm._v(_vm._s(_vm.icons.mdiPackageVariantClosed))])],1),_c('p',[_vm._v("กำลังจัดส่งสินค้า")])]),_c('div',{staticClass:"status-item-box"},[_c('div',{staticClass:"status-item",class:{
        active: _vm.isShipmentBeingProcess,
        current: _vm.isCurrentStatus(6, 10),
      }},[_c('v-icon',{attrs:{"dark":"","size":"4vw"}},[_vm._v(_vm._s(_vm.icons.mdiHumanDolly))])],1),_c('p',[_vm._v("อยู่ระหว่างขนส่ง")])]),_c('div',{staticClass:"status-item-box"},[_c('div',{staticClass:"status-item",class:{
        active: _vm.isShipmentInProcess && !_vm.isShipmentCancel,
        current: _vm.isCurrentStatus(11, 15),
        cancel: _vm.isShipmentCancel,
      }},[_c('v-icon',{attrs:{"dark":"","size":"4vw"}},[_vm._v(_vm._s(_vm.isShipmentCancel ? _vm.icons.mdiPackageVariantClosedRemove : _vm.icons.mdiTruckFast))])],1),_c('p',[_vm._v(_vm._s(_vm.isShipmentCancel ? "จัดส่งไม่สำเร็จ" : "กำลังจัดส่งพัสดุ"))])]),_c('div',{staticClass:"status-item-box"},[_c('div',{staticClass:"status-item",class:{
        active: _vm.isShipmentCompleted && !_vm.isShipmentCancel,
        current: _vm.isCurrentStatus(16, 20),
        revesre: _vm.isReverseCompleted,
      }},[_c('v-icon',{attrs:{"dark":"","size":"4vw"}},[_vm._v(_vm._s(_vm.icons.mdiCheckDecagram))])],1),_c('p',[_vm._v(_vm._s(_vm.isReverseCompleted ? "คืนพัสดุสำเร็จ" : "จัดส่งพัสดุสำเร็จ"))])])])}
var staticRenderFns = []

export { render, staticRenderFns }