import axios from "axios";

export const httpCilent = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

httpCilent.interceptors.response.use((response) => {
  if (response.status === 200 || response.status === 201) {
    return Promise.resolve(response);
  } else {
    return Promise.reject(response);
  }
});
