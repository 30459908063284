import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import TrackingView from "../views/tracking/TrackingView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/tracking",
  },
  {
    path: "/tracking",
    name: "trackingPage",
    component: TrackingView,
    meta: { title: "เช็คเลขพัสดุทุกขนส่ง จาก MyCloudFulfillment" },
  },
  {
    path: "***",
    redirect: "/tracking",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta?.title;
  next();
});

export default router;
